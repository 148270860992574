<template>
    <client-page>
        <section class="sub-section sub-section--visual">
            <v-container>
                <v-img max-width="798" src="/images/company/history/history/visual.jpg" class="visual-image"></v-img>
                <v-sheet class="sub-section--visual__sheet">
                    <v-breadcrumbs :items="breadcrumbs" class="pa-0">
                        <template v-slot:divider>
                            <v-icon color="#ddb57e">mdi-chevron-right</v-icon>
                        </template>
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                                <span class="font-size-12 font-weight-bold">
                                    {{ item.text }}
                                </span>
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <div class="title-line" />
                    <div class="tit tit--xxl font-tertiary mb-20px">The company's history</div>
                    <p class="txt txt--xs line-height-16">Learn more about the beginnings of the company and go on a journey through the most important events in the history of Villeroy &amp; Boch AG.</p>
                </v-sheet>
            </v-container>
        </section>

        <section class="sub-section sub-section--1">
            <v-container>
                <template v-for="item in items">
                    <v-sheet :key="item" color="transparent">
                        <v-card color="grey lighten-5" max-width="500" class="text-center mx-auto pt-20px pt-xl-30px pb-16px pb-xl-22px position-relative z-1">
                            <div class="font-size-12 tertiary--text line-height-11 mb-8px">
                                {{ item.year }}
                            </div>
                            <h3 class="tit tit--lg font-tertiary">
                                {{ item.name }}
                            </h3>
                        </v-card>

                        <template v-for="child in item.children">
                            <div :key="child" class="history-item">
                                <v-row align="center" class="row--lg">
                                    <v-col cols="12" xl="auto" class="history-item__year">
                                        <v-card color="grey lighten-5" rounded="circle" class="mx-auto position-relative z-1">
                                            <v-responsive :aspect-ratio="1 / 1" class="d-flex align-center">
                                                <div class="tit tit--sm font-tertiary font-weight-bold tertiary--text text-center">
                                                    {{ child.year }}
                                                </div>
                                            </v-responsive>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12" xl="" class="history-item__content">
                                        <v-card max-width="500" class="grey lighten-5 py-14px pb-xl-0" data-aos="fade-up">
                                            <div class="tit tit--xs mb-14px">
                                                {{ child.name }}
                                            </div>
                                            <template v-if="child.image">
                                                <v-img :src="child.image" eager />
                                            </template>
                                            <template v-if="child.video"> </template>
                                            <p :class="{ 'text-truncate-3': child.isTruncated }" class="txt txt--xs line-height-16 mt-14px">
                                                {{ child.text }}
                                            </p>
                                            <v-btn outlined color="primary" x-small class="mt-14px" @click="toggleTruncate(child)">
                                                {{ child.isTruncated ? "더 읽기" : "접기" }}
                                            </v-btn>
                                        </v-card>
                                    </v-col>
                                    <v-col xl="" class="history-item__blank" />
                                </v-row>
                            </div>
                        </template>
                    </v-sheet>
                </template>
                <v-sheet color="grey lighten-5" max-width="500" class="text-center pt-30px pb-22px mx-auto position-relative z-1">
                    <div class="tit tit--lg font-tertiary">ANNIVERSARY YEAR 2023 - ALL ABOUT 275 YEARS CREATING HOMES</div>
                </v-sheet>
                <v-sheet color="transparent" max-width="688" class="mx-auto">
                    <v-img src="/images/company/history/history/last.jpg" />
                    <p class="txt txt--xs mt-14px line-height-16">Since 1748, Villeroy &amp; Boch has stood for quality and sophisticated aesthetics, for forward-looking ideas and techniques relating to ceramics. It has always been Villeroy &amp; Boch's ambition to design our homes in order to enjoy life's moments. "Creating Homes" means more than furnishing a house. "Creating Homes" means realising one's personal ideas, one's own lifestyle and feeling at home.</p>
                </v-sheet>
            </v-container>
        </section>
    </client-page>
</template>

<script>
import History from "@/assets/data/history.json";
import ClientPage from "@/components/client/templates/client-page.vue";

export default {
    components: {
        ClientPage,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "Company",
                href: "/company/about/figures",
            },
            {
                text: "역사와 전통",
                href: "/company/history/history",
            },
            {
                text: "역사",
                href: "/company/history/history",
            },
        ],
        items: History.map((item) => ({
            ...item,
            children: item.children.map((child) => ({
                ...child,
                isTruncated: true,
            })),
        })),
    }),
    methods: {
        toggleTruncate(child) {
            child.isTruncated = !child.isTruncated;
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
    .v-main {
        background-color: var(--v-grey-lighten5);
    }
}
.sub-section {
    &--visual {
        .container {
            position: relative;
        }
        &__sheet {
            max-width: 90%;
            padding: 30px;
            margin: -220px auto 0;
            position: relative;
            z-index: 1;
            .title-line {
                width: 30px;
                height: 2px;
                background-color: var(--v-primary-base);
                margin: 20px 0;
            }
            .v-breadcrumbs {
                color: #ddb57e;
            }
        }
    }
    &--1 {
        padding-top: 40px;
    }
}
.history-item {
    position: relative;
    &::before {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 2px;
        height: 100%;
        background-color: var(--v-primary-base);
    }
    &__year {
        > .v-card {
            border: 2px solid var(--v-primary-base) !important;
            width: 90px;
        }
    }
    &__content {
        position: relative;
        z-index: 1;
    }
    &__blank {
        display: none;
    }
}
@media (min-width: 768px) {
    .sub-section {
        &--visual {
            &__sheet {
                max-width: 600px;
                padding: 50px;
                margin: 0;
                position: absolute;
                top: 50%;
                right: var(--container-gutter);
                transform: translateY(-50%);
                .title-line {
                    width: 30px;
                    margin: 20px 0;
                }
            }
            .visual-image {
                &::after {
                    content: "";
                    position: absolute;
                    left: 14px;
                    bottom: 0;
                    width: 2px;
                    background-color: var(--v-primary-base);
                    transform: translateY(100%);
                    height: 0;
                }
            }
        }
    }
}
@media (min-width: 1232px) {
    .sub-section {
        &--visual {
            &__sheet {
                max-width: 600px;
                padding: 50px;
                margin: 0;
                position: absolute;
                top: 50%;
                right: var(--container-gutter);
                transform: translateY(-50%);
                .title-line {
                    width: 30px;
                    margin: 20px 0;
                }
            }
        }
        &--1 {
            padding-top: 80px;
        }
    }
    .history-item {
        &__content {
            order: 1;
        }
        &__year {
            order: 2;
            > .v-card {
                width: 104px;
            }
        }
        &__blank {
            order: 3;
            display: block;
        }
        &:nth-child(odd) {
            .history-item {
                &__content {
                    order: 3;
                }
                &__year {
                    order: 2;
                }
                &__blank {
                    order: 1;
                }
            }
        }
    }
}
</style>