var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('section', {
    staticClass: "sub-section sub-section--visual"
  }, [_c('v-container', [_c('v-img', {
    staticClass: "visual-image",
    attrs: {
      "src": "/images/company/innovation/digital/visual.jpg"
    }
  }), _c('v-sheet', {
    staticClass: "sub-section--visual__sheet"
  }, [_c('v-breadcrumbs', {
    staticClass: "pa-0",
    attrs: {
      "items": _vm.breadcrumbs
    },
    scopedSlots: _vm._u([{
      key: "divider",
      fn: function () {
        return [_c('v-icon', {
          attrs: {
            "color": "#ddb57e"
          }
        }, [_vm._v("mdi-chevron-right")])];
      },
      proxy: true
    }, {
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-breadcrumbs-item', {
          attrs: {
            "href": item.href,
            "disabled": item.disabled
          }
        }, [_c('span', {
          staticClass: "font-size-12 font-weight-bold"
        }, [_vm._v(" " + _vm._s(item.text) + " ")])])];
      }
    }])
  }), _c('div', {
    staticClass: "title-line"
  }), _c('div', {
    staticClass: "tit tit--xxl font-tertiary mb-20px"
  }, [_vm._v("Driving the digital revolution")]), _c('p', {
    staticClass: "txt txt--xs line-height-16"
  }, [_vm._v("For the Bathroom & Wellness and Tableware divisions, the digital revolution represents an opportunity - and selected examples show how Villeroy & Boch is exploring these new horizons.")])], 1)], 1)], 1), _c('section', {
    staticClass: "sub-section sub-section--1"
  }, [_c('v-container', [_c('v-sheet', {
    staticClass: "sub-sheet px-xl-50px",
    attrs: {
      "color": "transparent"
    }
  }, [_c('iframe', {
    staticClass: "youtube mb-40px mb-xl-80px",
    attrs: {
      "src": "https://www.youtube.com/embed/jrZ0dnEozVg?si=waqOlvOqVZ73BXNC",
      "title": "YouTube video player",
      "frameborder": "0",
      "allow": "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
      "referrerpolicy": "strict-origin-when-cross-origin",
      "allowfullscreen": ""
    }
  }), _c('v-row', {
    staticClass: "row--xxl",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "xl": ""
    }
  }, [_c('div', {
    staticClass: "tit tit--xs font-tertiary mb-20px"
  }, [_vm._v("Bathroom planning 2.0")]), _c('div', {
    staticClass: "txt txt--xs line-height-16 grey--text text--lighten-1"
  }, [_c('p', [_vm._v(" Just a few clicks and the new bathroom is ready - at least when it comes to a virtual bathroom in Villeroy & Boch's online Bathroom Planner. This tool allows potential customers to design a rough outline of their new bathroom easily using their own computer or tablet.Just a few clicks and the new bathroom is ready - at least when it comes to a virtual bathroom in Villeroy & Boch's online Bathroom Planner. This tool allows potential customers to design a rough outline of their new bathroom easily using their own computer or tablet. ")]), _c('p', [_vm._v(" The user is guided through the planning process in just a few steps: enter the bathroom floor plan, add doors and windows, then place washbasins and toilets, bidets and urinals, baths, showers as well as bathroom furniture and mirrors from the current range - and done! Three-dimensional views and a floor plan with exact measurements are a good way to get a first impression of the completed bathroom. Villeroy & Boch sends a free Virtual Reality cardboard to customers so they can easily view their bathroom plan in 3D at home. After completing the bathroom plan, the user will be sent an email containing a link to the 3D view. Once the customer has received the Virtual Reality cardboard, they only have to click the link and put their smartphone into the Virtual Reality cardboard. The perfect three-dimensional visualisation makes it easy to imagine what the bathroom will look like - and provides planning security. ")])]), _c('v-img', {
    staticClass: "my-40px my-xl-80px",
    attrs: {
      "src": "/images/company/innovation/digital/image1.jpg"
    }
  }), _c('div', {
    staticClass: "tit tit--xs font-tertiary mb-20px"
  }, [_vm._v("Social Media @ Villeroy&Boch")]), _c('div', {
    staticClass: "txt txt--xs line-height-16 grey--text text--lighten-1"
  }, [_c('p', [_vm._v(" Over five billion people worldwide are online - more than half of them on Facebook and YouTube, every third person is active on Instagram. Social media channels are booming, and they offer an excellent platform for presenting companies, brands and products. For Villeroy & Boch, the social web is one of the main ways of reaching a target group that includes young international users and encouraging interaction. From Facebook to Instagram, YouTube, Twitter, Pinterest and WeChat: Villeroy & Boch has its own presence on the world's most widely used social media channels and actively addresses a large international fan community. ")]), _c('p', [_vm._v("In a small, internal social media content factory, channel-appropriate moving image content, images and stories are produced daily for the various social media platforms.")])]), _c('v-img', {
    staticClass: "my-40px my-xl-80px",
    attrs: {
      "src": "/images/company/innovation/digital/image2.jpg"
    }
  }), _c('div', {
    staticClass: "tit tit--xs font-tertiary mb-20px"
  }, [_vm._v("Networked thinking: cross channel at Villeroy&Boch")]), _c('div', {
    staticClass: "txt txt--xs line-height-16 grey--text text--lighten-1"
  }, [_c('p', [_vm._v(" The innovative ideas developed by the Dining & Lifestyle Division are not restricted to new product ranges, decors or ceramic shapes, but also extend to intelligent customer services. \"Cross channel\" is the guiding principle. These days, a company needs to be present with its customers - both online and offline. While personal advice in Villeroy & Boch stores is still very important, digital services are becoming increasingly essential. With the Personal Sales Assistant (PSA), the wish list with notification service and services such as Check & Reserve or Click & Collect, customers are digitally picked up and can conveniently shop digitally. Our dropshipment service is also worth mentioning: our customers can shop locally in our shops and then have their purchases conveniently sent to their homes. ")]), _c('p', [_vm._v("In addition to numerous other benefits, our customer programme My Club offers the opportunity to collect points that can be redeemed both online and offline when shopping.")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--lg flex-column"
  }, [_vm._l(_vm.items, function (item) {
    return [_c('v-col', {
      key: item,
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-card', {
      staticClass: "sns-card",
      attrs: {
        "img": item.image,
        "href": item.link,
        "width": "274",
        "target": "_blank",
        "tile": "",
        "flat": ""
      }
    }, [_c('v-responsive', {
      attrs: {
        "aspect-ratio": 1 / 1
      }
    }, [_c('div', {
      staticClass: "sns-card__inner"
    }, [_c('div', [_c('div', {
      staticClass: "sns-card__sub-title"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('div', {
      staticClass: "sns-card__title"
    }, [_vm._v(" Villeroy&Boch "), _c('br'), _vm._v(" on " + _vm._s(item.title) + " ")]), _c('div', {
      staticClass: "sns-card__line"
    }), _c('v-btn', {
      staticClass: "sns-card__button rounded-0",
      attrs: {
        "outlined": "",
        "color": "white"
      }
    }, [_vm._v("Find out more")])], 1)])])], 1)], 1)];
  })], 2)], 1)], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }