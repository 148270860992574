var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('section', {
    staticClass: "sub-section sub-section--visual"
  }, [_c('v-container', [_c('v-img', {
    staticClass: "visual-image",
    attrs: {
      "max-width": "798",
      "src": "/images/company/responsibility/visual.jpg"
    }
  }), _c('v-sheet', {
    staticClass: "sub-section--visual__sheet"
  }, [_c('v-breadcrumbs', {
    staticClass: "pa-0",
    attrs: {
      "items": _vm.breadcrumbs
    },
    scopedSlots: _vm._u([{
      key: "divider",
      fn: function () {
        return [_c('v-icon', {
          attrs: {
            "color": "#ddb57e"
          }
        }, [_vm._v("mdi-chevron-right")])];
      },
      proxy: true
    }, {
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-breadcrumbs-item', {
          attrs: {
            "href": item.href,
            "disabled": item.disabled
          }
        }, [_c('span', {
          staticClass: "font-size-12 font-weight-bold"
        }, [_vm._v(" " + _vm._s(item.text) + " ")])])];
      }
    }])
  }), _c('div', {
    staticClass: "title-line"
  }), _c('div', {
    staticClass: "tit tit--xxl font-tertiary mb-20px"
  }, [_vm._v("Taking responsibility")]), _c('p', {
    staticClass: "txt txt--xs line-height-16"
  }, [_vm._v("As an international company, Corporate Social Responsibility has been an essential pillar of Villeroy & Boch's corporate philosophy for years.")])], 1)], 1)], 1), _c('section', {
    staticClass: "sub-section sub-section--1"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--xl"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('div', {
    staticClass: "txt txt--xs line-height-16"
  }, [_vm._v(" Economy. Ecology. Social commitment. Villeroy & Boch views it as the company's duty to take responsibility for sustainable products as well as for its employees and fellow human beings. The efficient use of raw materials and energy and a commitment to cutting emissions are key elements of our environmental strategy. We have significantly reduced our volumes of waste water and pollution in recent years while at the same time continuously improving our energy efficiency by making targeted investments aimed at optimising our production processes. ")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('div', {
    staticClass: "txt txt--xs line-height-16"
  }, [_vm._v(" As a company with a rich heritage dating back to 1748, we have always viewed it as our duty to take responsibility in our cultural and social environment, too. This awareness is documented in our mission statement, under: “Villeroy & Boch — Maintaining the Tradition”. Villeroy & Boch is a strong and reliable partner to society. For centuries, we have been pursuing projects and actions designed to enhance people's living environments, in particular at our main sites. We also encourage our employees to take responsibility and promote staff development as part of a sustainable human resources policy. ")])])], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }