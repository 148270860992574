<template>
    <client-page>
        <section class="sub-section sub-section--visual">
            <v-container>
                <v-img src="/images/company/about/vision/visual.jpg" class="visual-image">
                    <div class="vertical-title">Vision &amp; Mission</div>
                </v-img>
                <v-sheet class="sub-section--visual__sheet">
                    <v-breadcrumbs :items="breadcrumbs" class="pa-0">
                        <template v-slot:divider>
                            <v-icon color="#ddb57e">mdi-chevron-right</v-icon>
                        </template>
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                                <span class="font-size-12 font-weight-bold">
                                    {{ item.text }}
                                </span>
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <div class="title-line" />
                    <div class="tit tit--xxl font-tertiary mb-20px">Vision &amp; Mission</div>
                    <p class="txt txt--xs line-height-16">A common goal. We at Villeroy &amp; Boch have always known how to create personal experiences with our unique ceramics. Spurred on by our passion to keep setting new standards, our forward-looking approach allows us to develop creative and innovative solutions for the future. Resulting in environments where our customers feel at home.</p>
                </v-sheet>
            </v-container>
        </section>

        <section class="sub-section sub-section--1">
            <v-container>
                <v-row no-gutters justify="space-between">
                    <v-col cols="auto">
                        <v-sheet color="transparent" class="sub-sheet sub-sheet--left">
                            <div class="tit tit--xs font-tertiary mb-20px">Our vision: A house becomes a home</div>
                            <p class="txt txt--xs line-height-16 grey--text text--lighten-1">A “home” is created only when a house is furnished in a way that reflects the personal vision and style of those who live in it. We help make this happen ma-1 for everyone, everywhere.</p>

                            <v-img src="/images/company/about/vision/image1.jpg" />
                        </v-sheet>
                    </v-col>

                    <v-col cols="auto">
                        <v-sheet color="transparent" class="sub-sheet sub-sheet--right">
                            <v-img src="/images/company/about/vision/image2.jpg" />

                            <div class="tit tit--xs font-tertiary mb-20px">Our mission: We create feel-good moments and spaces using premium ceramics</div>
                            <div class="txt txt--xs line-height-16 grey--text text--lighten-1">
                                <p>
                                    Always at the forefront of our endeavors is our strong understanding of the needs and wishes of our customers. This knowledge affords us the pleasure of being instrumental in helping to create a particular character for every home. Your home should be comfortable so we imbue our products with all our passion, creativity and design expertise, and pair it with our technical and conceptual know-how. With our brands, lifestyles and price segments, we offer both
                                    superior individual items for your home and perfect complete solutions.
                                </p>
                                <p>Ceramics is our strength and is in our genes. Wherever possible, we rely on the superior quality of ceramics over other materials. Our expertise and passion for ceramics drives us forward to set benchmarks and to always represent the utmost quality and innovation in each segment with our brands.</p>
                                <p>Employing our ceramics and design expertise, we build on the potential for diversification in supplementary product areas, thus creating living environments where our customers feel at home - right from the start and every day anew.</p>
                            </div>
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-container>
        </section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";

export default {
    components: {
        ClientPage,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "Company",
                href: "/company/about/figures",
            },
            {
                text: "빌레로이앤보흐",
                href: "/company/about/figures",
            },
            {
                text: "비전과 미션",
                href: "/company/about/vision",
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
    .v-main {
        background-color: var(--v-grey-lighten5);
    }
}
.sub-section {
    &--visual {
        &__sheet {
            max-width: 90%;
            padding: 30px;
            position: relative;
            z-index: 1;
            margin: -60px auto 0;
            .title-line {
                width: 30px;
                height: 2px;
                background-color: var(--v-primary-base);
                margin: 20px 0;
            }
            .v-breadcrumbs {
                color: #ddb57e;
            }
        }
        .visual-image {
            position: relative;
            overflow: visible;
            .vertical-title {
                display: none;
            }
        }
    }
    &--1 {
        padding-top: 40px;
    }
    .sub-sheet {
        max-width: 90%;
        margin: 0 auto;
        &--left {
            padding-top: 40px;
            > .v-image {
                margin-top: 40px;
            }
        }
        &--right {
            > .v-image {
                margin-top: 40px;
                margin-bottom: 40px;
            }
        }
    }
}
@media (min-width: 1232px) {
    .sub-section {
        &--visual {
            &__sheet {
                max-width: 600px;
                padding: 50px;
                margin-top: -180px;
                margin-left: 50px;
                .title-line {
                    width: 30px;
                    margin: 20px 0;
                }
            }
            .visual-image {
                &::after {
                    content: "";
                    position: absolute;
                    left: 14px;
                    bottom: 0;
                    width: 2px;
                    background-color: var(--v-primary-base);
                    transform: translateY(100%);
                    height: 0;
                    animation: verticalLine 0.5s forwards ease-in-out;
                }
                .vertical-title {
                    display: block;
                    position: absolute;
                    left: -84px;
                    bottom: -242px;
                    font-size: var(--tit-font-size-sm);
                    font-family: var(--font-tertiary);
                    font-style: italic;
                    color: var(--v-primary-base);
                    line-height: 1;
                    transform: translateY(-40px) rotate(90deg);
                    opacity: 0;
                    animation: verticalTitle 0.5s forwards ease-in-out 0.5s;
                }
            }
        }
        &--1 {
            padding-top: 40px;
        }
        .sub-sheet {
            max-width: 500px;
            &--left {
                margin-left: 100px;
                padding-top: 80px;
                > .v-image {
                    margin-top: 80px;
                }
            }
            &--right {
                transform: translateY(-160px);
                > .v-image {
                    margin-bottom: 80px;
                }
            }
        }
    }
}

// Animations
@keyframes verticalLine {
    from {
        height: 0;
    }
    to {
        height: 120px;
    }
}
@keyframes verticalTitle {
    from {
        transform: translateY(-40px) rotate(90deg);
        opacity: 0;
    }
    to {
        transform: translateY(0) rotate(90deg);
        opacity: 1;
    }
}
</style>