<template>
    <client-page>
        <section class="sub-section sub-section--visual">
            <v-container>
                <v-img src="/images/company/innovation/digital/visual.jpg" class="visual-image" />
                <v-sheet class="sub-section--visual__sheet">
                    <v-breadcrumbs :items="breadcrumbs" class="pa-0">
                        <template v-slot:divider>
                            <v-icon color="#ddb57e">mdi-chevron-right</v-icon>
                        </template>
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                                <span class="font-size-12 font-weight-bold">
                                    {{ item.text }}
                                </span>
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <div class="title-line" />
                    <div class="tit tit--xxl font-tertiary mb-20px">Driving the digital revolution</div>
                    <p class="txt txt--xs line-height-16">For the Bathroom &amp; Wellness and Tableware divisions, the digital revolution represents an opportunity - and selected examples show how Villeroy &amp; Boch is exploring these new horizons.</p>
                </v-sheet>
            </v-container>
        </section>

        <section class="sub-section sub-section--1">
            <v-container>
                <v-sheet color="transparent" class="sub-sheet px-xl-50px">
                    <iframe src="https://www.youtube.com/embed/jrZ0dnEozVg?si=waqOlvOqVZ73BXNC" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen class="youtube mb-40px mb-xl-80px" />

                    <v-row justify="center" class="row--xxl">
                        <v-col cols="12" xl="">
                            <div class="tit tit--xs font-tertiary mb-20px">Bathroom planning 2.0</div>
                            <div class="txt txt--xs line-height-16 grey--text text--lighten-1">
                                <p>
                                    Just a few clicks and the new bathroom is ready - at least when it comes to a virtual bathroom in Villeroy &amp; Boch's online Bathroom Planner. This tool allows potential customers to design a rough outline of their new bathroom easily using their own computer or tablet.Just a few clicks and the new bathroom is ready - at least when it comes to a virtual bathroom in Villeroy &amp; Boch's online Bathroom Planner. This tool allows potential customers to design
                                    a rough outline of their new bathroom easily using their own computer or tablet.
                                </p>
                                <p>
                                    The user is guided through the planning process in just a few steps: enter the bathroom floor plan, add doors and windows, then place washbasins and toilets, bidets and urinals, baths, showers as well as bathroom furniture and mirrors from the current range - and done! Three-dimensional views and a floor plan with exact measurements are a good way to get a first impression of the completed bathroom. Villeroy &amp; Boch sends a free Virtual Reality cardboard to
                                    customers so they can easily view their bathroom plan in 3D at home. After completing the bathroom plan, the user will be sent an email containing a link to the 3D view. Once the customer has received the Virtual Reality cardboard, they only have to click the link and put their smartphone into the Virtual Reality cardboard. The perfect three-dimensional visualisation makes it easy to imagine what the bathroom will look like - and provides planning security.
                                </p>
                            </div>

                            <v-img src="/images/company/innovation/digital/image1.jpg" class="my-40px my-xl-80px" />

                            <div class="tit tit--xs font-tertiary mb-20px">Social Media @ Villeroy&amp;Boch</div>
                            <div class="txt txt--xs line-height-16 grey--text text--lighten-1">
                                <p>
                                    Over five billion people worldwide are online - more than half of them on Facebook and YouTube, every third person is active on Instagram. Social media channels are booming, and they offer an excellent platform for presenting companies, brands and products. For Villeroy &amp; Boch, the social web is one of the main ways of reaching a target group that includes young international users and encouraging interaction. From Facebook to Instagram, YouTube, Twitter,
                                    Pinterest and WeChat: Villeroy &amp; Boch has its own presence on the world's most widely used social media channels and actively addresses a large international fan community.
                                </p>
                                <p>In a small, internal social media content factory, channel-appropriate moving image content, images and stories are produced daily for the various social media platforms.</p>
                            </div>

                            <v-img src="/images/company/innovation/digital/image2.jpg" class="my-40px my-xl-80px" />

                            <div class="tit tit--xs font-tertiary mb-20px">Networked thinking: cross channel at Villeroy&amp;Boch</div>
                            <div class="txt txt--xs line-height-16 grey--text text--lighten-1">
                                <p>
                                    The innovative ideas developed by the Dining &amp; Lifestyle Division are not restricted to new product ranges, decors or ceramic shapes, but also extend to intelligent customer services. "Cross channel" is the guiding principle. These days, a company needs to be present with its customers - both online and offline. While personal advice in Villeroy &amp; Boch stores is still very important, digital services are becoming increasingly essential. With the
                                    Personal Sales Assistant (PSA), the wish list with notification service and services such as Check &amp; Reserve or Click &amp; Collect, customers are digitally picked up and can conveniently shop digitally. Our dropshipment service is also worth mentioning: our customers can shop locally in our shops and then have their purchases conveniently sent to their homes.
                                </p>
                                <p>In addition to numerous other benefits, our customer programme My Club offers the opportunity to collect points that can be redeemed both online and offline when shopping.</p>
                            </div>
                        </v-col>
                        <v-col cols="auto">
                            <v-row class="row--lg flex-column">
                                <template v-for="item in items">
                                    <v-col cols="auto" :key="item">
                                        <v-card :img="item.image" :href="item.link" width="274" target="_blank" tile flat class="sns-card">
                                            <v-responsive :aspect-ratio="1 / 1">
                                                <div class="sns-card__inner">
                                                    <div>
                                                        <div class="sns-card__sub-title">
                                                            {{ item.title }}
                                                        </div>
                                                        <div class="sns-card__title">
                                                            Villeroy&amp;Boch <br />
                                                            on {{ item.title }}
                                                        </div>
                                                        <div class="sns-card__line" />
                                                        <v-btn outlined color="white" class="sns-card__button rounded-0">Find out more</v-btn>
                                                    </div>
                                                </div>
                                            </v-responsive>
                                        </v-card>
                                    </v-col>
                                </template>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-sheet>
            </v-container>
        </section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";

export default {
    components: {
        ClientPage,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "Company",
                href: "/company/about/figures",
            },
            {
                text: "품질과 혁신",
                href: "/company/innovation/products",
            },
            {
                text: "Digital Services",
                href: "/company/innovation/digital",
            },
        ],
        items: [
            {
                title: "Facebook",
                image: "/images/company/innovation/digital/facebook.jpg",
                link: "https://www.facebook.com/VilleroyandBoch",
            },
            {
                title: "Instagram",
                image: "/images/company/innovation/digital/instagram.jpg",
                link: "https://www.instagram.com/villeroyboch/",
            },
            {
                title: "Pinterest",
                image: "/images/company/innovation/digital/pinterest.jpg",
                link: "https://www.pinterest.de/villeroyandboch/",
            },
            {
                title: "YouTube",
                image: "/images/company/innovation/digital/youtube.jpg",
                link: "https://www.youtube.com/user/VilleroyundBoch",
            },
            {
                title: "Twitter",
                image: "/images/company/innovation/digital/x.jpg",
                link: "https://x.com/i/flow/login?redirect_after_login=%2Fvilleroyandboch",
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
    .v-main {
        background-color: var(--v-grey-lighten5);
    }
}
.sub-section {
    &--visual {
        &__sheet {
            max-width: 90%;
            padding: 30px;
            position: relative;
            z-index: 1;
            margin: -60px auto 0;
            .title-line {
                width: 30px;
                height: 2px;
                background-color: var(--v-primary-base);
                margin: 20px 0;
            }
            .v-breadcrumbs {
                color: #ddb57e;
            }
        }
        .visual-image {
            position: relative;
            overflow: visible;
            .vertical-title {
                display: none;
            }
        }
    }
    &--1 {
        padding-top: 40px;
    }
    .sub-sheet {
        max-width: 90%;
        margin: 0 auto;
    }
}
.youtube {
    width: 100%;
    aspect-ratio: 16/9;
}
.sns-card {
    overflow: hidden;
    &__inner {
        height: 100%;
        position: relative;
        z-index: 1;
        color: #fff;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.6);
            z-index: -1;
        }
    }
    &__sub-title {
        color: #ddb57e;
        font-size: 12px;
        text-transform: uppercase;
    }
    &__line {
        margin: 14px auto 28px;
        width: 8px;
        height: 2px;
        background-color: #ddb57e;
        transition: all ease-out 0.15s;;
    }
    &__title {
        font-family: var(--font-tertiary);
        font-weight: 700;
        font-size: var(--tit-font-size-sm);
        line-height: 1.1;
    }
    &__button {
        border-width: 2px;
    }
    &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background: inherit;
        width: 100%;
        height: 100%;
        transition: all 0.2s;
    }
    &:hover {
        .sns-card {
            &__line {
                width: 20px;
            }
            &__button {
                background-color: #ddb57e !important;
                border-color: #ddb57e;
            }
        }
        &::after {
            transform: scale(1.1);
        }
    }
}
@media (min-width: 1232px) {
    .sub-section {
        &--visual {
            &__sheet {
                max-width: 600px;
                padding: 50px;
                margin-top: -180px;
                margin-left: 50px;
                .title-line {
                    width: 30px;
                    margin: 20px 0;
                }
            }
            .visual-image {
                &::after {
                    content: "";
                    position: absolute;
                    left: 14px;
                    bottom: 0;
                    width: 2px;
                    background-color: var(--v-primary-base);
                    transform: translateY(100%);
                    height: 0;
                    animation: verticalLine 0.5s forwards ease-in-out;
                }
                .vertical-title {
                    display: block;
                    position: absolute;
                    left: -90px;
                    bottom: -250px;
                    font-size: var(--tit-font-size-sm);
                    font-family: var(--font-tertiary);
                    font-style: italic;
                    color: var(--v-primary-base);
                    line-height: 1;
                    transform: translateY(-40px) rotate(90deg);
                    opacity: 0;
                    animation: verticalTitle 0.5s forwards ease-in-out 0.5s;
                }
            }
        }
        &--1 {
            padding-top: 80px;
        }
        .sub-sheet {
            max-width: initial;
        }
    }
}
</style>