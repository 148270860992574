<template>
    <client-page>
        <section class="sub-section sub-section--visual">
            <v-container>
                <v-img src="/images/company/innovation/awards/visual.jpg" class="visual-image">
                    <div class="vertical-title">Awards</div>
                </v-img>
                <v-sheet class="sub-section--visual__sheet">
                    <v-breadcrumbs :items="breadcrumbs" class="pa-0">
                        <template v-slot:divider>
                            <v-icon color="#ddb57e">mdi-chevron-right</v-icon>
                        </template>
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                                <span class="font-size-12 font-weight-bold">
                                    {{ item.text }}
                                </span>
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <div class="title-line" />
                    <div class="tit tit--xxl font-tertiary mb-20px">Award-winning design competence</div>
                    <p class="txt txt--xs line-height-16">Villeroy &amp; Boch stands for outstanding design and functionality.</p>
                </v-sheet>
            </v-container>
        </section>

        <section class="sub-section sub-section--1">
            <v-container>
                <v-row no-gutters justify="space-between">
                    <v-col cols="auto">
                        <v-sheet color="transparent" class="sub-sheet sub-sheet--left">
                            <div class="tit tit--xs font-tertiary mb-20px">Octagon</div>
                            <p class="txt txt--xs line-height-16 grey--text text--lighten-1">
                                In 2015, Octagon received the prestigious GOOD DESIGN™ Award and was a finalist at the Boutique Design trade fair in New York, in the "Best Product Design" category for the bathroom sector. This innovative design has once again convinced design experts in 2016: Octagon has been selected to receive the iF DESIGN AWARD, one of the most important design prizes in the world. The inside of the unusual basin resembles a polished octagonal crystal. Its highly precise
                                edges, angles and facets were made possible by TitanCeram, the new material developed by Villeroy &amp; Boch. But the basin is not the only extraordinary feature. Its positioning on a pedestal finished with leather or a real wood or stone veneer make the free-standing washbasin a real design highlight in the bathroom.
                            </p>

                            <v-img src="/images/company/innovation/awards/image1.jpg" class="my-40px my-xl-80px" />

                            <div class="tit tit--xs font-tertiary mb-20px">Just Silence</div>
                            <p class="txt txt--xs line-height-16 grey--text text--lighten-1">
                                Its simplicity is one of the charms of the Just Silence outdoor hot tub, an award-winner since February 2015. A jury of 50 design experts selected the hot tub for the iF Design Award. As well as its minimalist colour scheme and different lying positions, the 2.13m by 2.24m pool also impressed with its innovative seating concept. Just Silence allows up to five people to sit across from each other in a staggered formation without sacrificing legroom!
                            </p>

                            <v-img src="/images/company/innovation/awards/image2.jpg" class="my-40px my-xl-80px" />

                            <div class="tit tit--xs font-tertiary mb-20px">La Boule</div>
                            <p class="txt txt--xs line-height-16 grey--text text--lighten-1">
                                La Boule takes Villeroy &amp; Boch back to its original strengths: ceramic expertise and spectacular design. Helen von Boch wrote design history in the 1970s with the “Sphere”. With La Boule, the modern new interpretation of the Sphere, Villeroy &amp; Boch is making a break from 1970s style and function. As a tableware set for two people, La Boule is ideally aligned with the needs of today's design lovers. It consists of two bowls, two deep and two flat plates and
                                a serving dish in solid-colour Premium Porcelain. Available in matt black, shiny white and in a contrasting black-and-white version, La Boule was selected by the Red Dot Design Award jury as the “Winner 2020”.
                            </p>

                            <v-img src="/images/company/innovation/awards/image3.jpg" class="my-40px my-xl-80px" />

                            <div class="tit tit--xs font-tertiary mb-20px">MetroChic</div>
                            <p class="txt txt--xs line-height-16 grey--text text--lighten-1">
                                Villeroy &amp; Boch is delighted at winning another renowned Red Dot Design Award. The glasses from the MetroChic collection impressed the jury in the “Best of the Best” category. MetroChic is a timelessly elegant Villeroy &amp; Boch Signature collection that contains crockery, cutlery and gifts. Boasting a clear and distinct design, the MetroChic Glass drinking glasses were developed for 2020 and accentuate the elegance of MetroChic.
                            </p>

                            <v-img src="/images/company/innovation/awards/image4.jpg" class="my-40px my-xl-80px" />

                            <div class="tit tit--xs font-tertiary mb-20px">DirectFlush</div>
                            <p class="txt txt--xs line-height-16 grey--text text--lighten-1">
                                A double honour for the Omnia Architectura toilet with DirectFlush technology: it was selected by an expert jury for the universal design award 2014 and as the universal design consumer favourite 2014 by a panel of consumers. With its innovative DirectFlush toilet technology, Villeroy &amp; Boch is setting new standards in hygiene. The rimless design allows quick and easy cleaning. This makes DirectFlush ideal for meeting today’s high hygiene standards in
                                hospitals and public buildings that receive high numbers of visitors, as well as in private households.
                            </p>
                        </v-sheet>
                    </v-col>

                    <v-col cols="auto">
                        <v-sheet color="transparent" class="sub-sheet sub-sheet--right">
                            <v-img src="/images/company/innovation/awards/image5.jpg" class="my-40px mt-xl-0 mb-xl-80px" />

                            <div class="tit tit--xs font-tertiary mb-20px">Manufacture Collier</div>
                            <p class="txt txt--xs line-height-16 grey--text text--lighten-1">
                                Home accessories in natural colours boast a casual elegance while creating a positive atmosphere with their warm, earthy hues. This calming effect can be easily achieved with distinctive pieces such as the Manufacture Collier vases. Awarded the Red Dot Award 2018, Manufacture Collier is setting trends in the new soft colours of Beige and Taupe and emphasises the minimalist look of modern living spaces with the elegance of natural colours.
                            </p>

                            <v-img src="/images/company/innovation/awards/image6.jpg" class="my-40px my-xl-80px" />

                            <div class="tit tit--xs font-tertiary mb-20px">it's my match</div>
                            <div class="txt txt--xs line-height-16 grey--text text--lighten-1">
                                <p>The winner of the year 2020 is the “it's my match” trend collection, having received the German Design Award, the Red Dot Design Award and the KitchenInnovation Award in 2019.</p>
                                <p>The fuss-free “it's my match” trend collection was developed by young people for young people and consists of four multifunctional and versatile items: a bowl, serving bowl, mug and plate are available with three relief structures and in the on-trend colours of Powder, Green and White. They can be combined in many different creative ways and thus offer everyone the opportunity to create their own look of perfect imperfection.</p>
                            </div>

                            <v-img src="/images/company/innovation/awards/image7.jpg" class="my-40px my-xl-80px" />

                            <div class="tit tit--xs font-tertiary mb-20px">Metric Art</div>
                            <p class="txt txt--xs line-height-16 grey--text text--lighten-1">The Metric Art ceramic kitchen sink won the Interior Innovation Award 2011. The sink's design revolves around clear lines, a simple shape and reduced radii. The key feature of the design is its striking border which emphasises the basin area.</p>

                            <v-img src="/images/company/innovation/awards/image8.jpg" class="my-40px my-xl-80px" />

                            <div class="tit tit--xs font-tertiary mb-20px">ViClean-U shower-toilet</div>
                            <p class="txt txt--xs line-height-16 grey--text text--lighten-1">The ViClean-U shower-toilet was one of the prize-winners at the iF product design awards 2014. The innovative toilet offers gentle intimate cleaning thanks to a wide range of customisable functions. The water temperature and pressure can be regulated and the position of the nozzle adjusted. A warm flow of air with different temperature settings provides pleasant drying.</p>

                            <v-img src="/images/company/innovation/awards/image9.jpg" class="mt-40px mt-xl-80px" />
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-container>
        </section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";

export default {
    components: {
        ClientPage,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "Company",
                href: "/company/about/figures",
            },
            {
                text: "품질과 혁신",
                href: "/company/innovation/products",
            },
            {
                text: "수상경력",
                href: "/company/innovation/awards",
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
    .v-main {
        background-color: var(--v-grey-lighten5);
    }
}
.sub-section {
    &--visual {
        &__sheet {
            max-width: 90%;
            padding: 30px;
            position: relative;
            z-index: 1;
            margin: -60px auto 0;
            .title-line {
                width: 30px;
                height: 2px;
                background-color: var(--v-primary-base);
                margin: 20px 0;
            }
            .v-breadcrumbs {
                color: #ddb57e;
            }
        }
        .visual-image {
            position: relative;
            overflow: visible;
            .vertical-title {
                display: none;
            }
        }
    }
    &--1 {
        padding-top: 40px;
    }
    .sub-sheet {
        max-width: 90%;
        margin: 0 auto;
        &--left {
            padding-top: 40px;
        }
    }
}
@media (min-width: 1232px) {
    .sub-section {
        &--visual {
            &__sheet {
                max-width: 600px;
                padding: 50px;
                margin-top: -180px;
                margin-left: 50px;
                .title-line {
                    width: 30px;
                    margin: 20px 0;
                }
            }
            .visual-image {
                &::after {
                    content: "";
                    position: absolute;
                    left: 14px;
                    bottom: 0;
                    width: 2px;
                    background-color: var(--v-primary-base);
                    transform: translateY(100%);
                    height: 0;
                    animation: verticalLine 0.5s forwards ease-in-out;
                }
                .vertical-title {
                    display: block;
                    position: absolute;
                    left: -24px;
                    bottom: -184px;
                    font-size: var(--tit-font-size-sm);
                    font-family: var(--font-tertiary);
                    font-style: italic;
                    color: var(--v-primary-base);
                    line-height: 1;
                    transform: translateY(-40px) rotate(90deg);
                    opacity: 0;
                    animation: verticalTitle 0.5s forwards ease-in-out 0.5s;
                }
            }
        }
        &--1 {
            padding-top: 40px;
        }
        .sub-sheet {
            max-width: 500px;
            &--left {
                margin-left: 100px;
                padding-top: 80px;
            }
            &--right {
                transform: translateY(-160px);
            }
        }
    }
}

// Animations
@keyframes verticalLine {
    from {
        height: 0;
    }
    to {
        height: 120px;
    }
}
@keyframes verticalTitle {
    from {
        transform: translateY(-40px) rotate(90deg);
        opacity: 0;
    }
    to {
        transform: translateY(0) rotate(90deg);
        opacity: 1;
    }
}
</style>