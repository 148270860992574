var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('section', {
    staticClass: "sub-section sub-section--visual"
  }, [_c('v-container', [_c('v-img', {
    staticClass: "visual-image",
    attrs: {
      "max-width": "798",
      "src": "/images/company/brands/visual.jpg"
    }
  }), _c('v-sheet', {
    staticClass: "sub-section--visual__sheet"
  }, [_c('v-breadcrumbs', {
    staticClass: "pa-0",
    attrs: {
      "items": _vm.breadcrumbs
    },
    scopedSlots: _vm._u([{
      key: "divider",
      fn: function () {
        return [_c('v-icon', {
          attrs: {
            "color": "#ddb57e"
          }
        }, [_vm._v("mdi-chevron-right")])];
      },
      proxy: true
    }, {
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-breadcrumbs-item', {
          attrs: {
            "href": item.href,
            "disabled": item.disabled
          }
        }, [_c('span', {
          staticClass: "font-size-12 font-weight-bold"
        }, [_vm._v(" " + _vm._s(item.text) + " ")])])];
      }
    }])
  }), _c('div', {
    staticClass: "title-line"
  }), _c('div', {
    staticClass: "tit tit--xxl font-tertiary mb-20px"
  }, [_vm._v("All Villeroy&Boch brands")]), _c('p', {
    staticClass: "txt txt--xs line-height-16"
  }, [_vm._v("Villeroy & Boch AG is represented in over 125 countries. To offer customers all over the world premium quality and a comprehensive product portfolio, in addition to the Villeroy & Boch brand, our range includes other successful brands designed to enhance people's living environments. These local product brands are also pillars in the group's internationalisation.")])], 1)], 1)], 1), _c('section', {
    staticClass: "sub-section sub-section--1"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--xl"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-row', {
    staticClass: "row--lg"
  }, [_vm._l(_vm.left, function (item) {
    return [_c('v-col', {
      key: item,
      attrs: {
        "cols": "12"
      }
    }, [_c('v-card', {
      staticClass: "brand-card",
      attrs: {
        "tile": "",
        "flat": "",
        "min-height": "268",
        "href": item.link,
        "target": "_blank"
      }
    }, [_c('v-card', {
      staticClass: "brand-card__image",
      attrs: {
        "tile": "",
        "flat": "",
        "outlined": "",
        "width": "208"
      }
    }, [_c('v-responsive', {
      attrs: {
        "aspect-ratio": 1 / 1
      }
    }, [_c('v-img', {
      attrs: {
        "src": "/images/test-img.jpg"
      }
    })], 1)], 1), _c('div', [_c('div', {
      staticClass: "tit tit--xs line-hegiht-11 font-weight-bold"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('div', {
      staticClass: "font-size-12 tertiary--text mt-10px mb-20px"
    }, [_vm._v(" " + _vm._s(item.subtitle) + " ")]), _c('p', {
      staticClass: "txt txt--xs"
    }, [_vm._v(" " + _vm._s(item.text) + " ")]), _c('div', {
      staticClass: "brand-card__find"
    }, [_vm._v("더 알아보기")])])], 1)], 1)];
  })], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-row', {
    staticClass: "row--lg pt-lg-52px"
  }, [_vm._l(_vm.right, function (item) {
    return [_c('v-col', {
      key: item,
      attrs: {
        "cols": "12"
      }
    }, [_c('v-card', {
      staticClass: "brand-card",
      attrs: {
        "tile": "",
        "flat": "",
        "min-height": "268",
        "href": item.link,
        "target": "_blank"
      }
    }, [_c('v-card', {
      staticClass: "brand-card__image",
      attrs: {
        "tile": "",
        "flat": "",
        "outlined": "",
        "width": "208"
      }
    }, [_c('v-responsive', {
      attrs: {
        "aspect-ratio": 1 / 1
      }
    }, [_c('v-img', {
      attrs: {
        "src": "/images/test-img.jpg"
      }
    })], 1)], 1), _c('div', [_c('div', {
      staticClass: "tit tit--xs line-hegiht-11 font-weight-bold"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('div', {
      staticClass: "font-size-12 tertiary--text mt-10px mb-20px"
    }, [_vm._v(" " + _vm._s(item.subtitle) + " ")]), _c('p', {
      staticClass: "txt txt--xs"
    }, [_vm._v(" " + _vm._s(item.text) + " ")]), _c('div', {
      staticClass: "brand-card__find"
    }, [_vm._v("더 알아보기")])])], 1)], 1)];
  })], 2)], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }