<template>
    <client-page>
        <section class="sub-section sub-section--visual">
            <v-container>
                <v-img max-width="798" src="/images/company/responsibility/visual.jpg" class="visual-image" />
                <v-sheet class="sub-section--visual__sheet">
                    <v-breadcrumbs :items="breadcrumbs" class="pa-0">
                        <template v-slot:divider>
                            <v-icon color="#ddb57e">mdi-chevron-right</v-icon>
                        </template>
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                                <span class="font-size-12 font-weight-bold">
                                    {{ item.text }}
                                </span>
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <div class="title-line" />
                    <div class="tit tit--xxl font-tertiary mb-20px">Taking responsibility</div>
                    <p class="txt txt--xs line-height-16">As an international company, Corporate Social Responsibility has been an essential pillar of Villeroy & Boch's corporate philosophy for years.</p>
                </v-sheet>
            </v-container>
        </section>

        <section class="sub-section sub-section--1">
            <v-container>
                <v-row class="row--xl">
                    <v-col cols="12" lg="6">
                        <div class="txt txt--xs line-height-16">
                            Economy. Ecology. Social commitment. Villeroy & Boch views it as the company's duty to take responsibility for sustainable products as well as for its employees and fellow human beings. The efficient use of raw materials and energy and a commitment to cutting emissions are key elements of our environmental strategy. We have significantly reduced our volumes of waste water and pollution in recent years while at the same time continuously improving our energy efficiency
                            by making targeted investments aimed at optimising our production processes.
                        </div>
                    </v-col>

                    <v-col cols="12" lg="6">
                        <div class="txt txt--xs line-height-16">
                            As a company with a rich heritage dating back to 1748, we have always viewed it as our duty to take responsibility in our cultural and social environment, too. This awareness is documented in our mission statement, under: “Villeroy & Boch — Maintaining the Tradition”. Villeroy & Boch is a strong and reliable partner to society. For centuries, we have been pursuing projects and actions designed to enhance people's living environments, in particular at our main sites.
                            We also encourage our employees to take responsibility and promote staff development as part of a sustainable human resources policy.
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";

export default {
    components: {
        ClientPage,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "Company",
                href: "/company/about/figures",
            },
            {
                text: "사회적 책임",
                href: "/company/responsibility",
            },
        ],
        left: [
            {
                image: "/images/test-img.jpg",
                title: "왼쪽 브랜드 이름",
                subtitle: "브랜드 서브타이틀",
                text: "브랜드 설명이 옵니다. 브랜드 설명이 옵니다. 브랜드 설명이 옵니다. 브랜드 설명이 옵니다. 브랜드 설명이 옵니다.",
                link: "/",
            },
            {
                image: "/images/test-img.jpg",
                title: "왼쪽 브랜드 이름",
                subtitle: "브랜드 서브타이틀",
                text: "브랜드 설명이 옵니다. 브랜드 설명이 옵니다. 브랜드 설명이 옵니다. 브랜드 설명이 옵니다. 브랜드 설명이 옵니다.",
                link: "/",
            },
            {
                image: "/images/test-img.jpg",
                title: "왼쪽 브랜드 이름",
                subtitle: "브랜드 서브타이틀",
                text: "브랜드 설명이 옵니다. 브랜드 설명이 옵니다. 브랜드 설명이 옵니다. 브랜드 설명이 옵니다. 브랜드 설명이 옵니다.",
                link: "/",
            },
        ],
        right: [
            {
                image: "/images/test-img.jpg",
                title: "오른쪽 브랜드 이름",
                subtitle: "브랜드 서브타이틀",
                text: "브랜드 설명이 옵니다. 브랜드 설명이 옵니다. 브랜드 설명이 옵니다. 브랜드 설명이 옵니다. 브랜드 설명이 옵니다.",
                link: "/",
            },
            {
                image: "/images/test-img.jpg",
                title: "오른쪽 브랜드 이름",
                subtitle: "브랜드 서브타이틀",
                text: "브랜드 설명이 옵니다. 브랜드 설명이 옵니다. 브랜드 설명이 옵니다. 브랜드 설명이 옵니다. 브랜드 설명이 옵니다.",
                link: "/",
            },
            {
                image: "/images/test-img.jpg",
                title: "오른쪽 브랜드 이름",
                subtitle: "브랜드 서브타이틀",
                text: "브랜드 설명이 옵니다. 브랜드 설명이 옵니다. 브랜드 설명이 옵니다. 브랜드 설명이 옵니다. 브랜드 설명이 옵니다.",
                link: "/",
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
    .v-main {
        background-color: var(--v-grey-lighten5);
    }
}
.sub-section {
    &--visual {
        .container {
            position: relative;
        }
        &__sheet {
            max-width: 90%;
            padding: 30px;
            margin: -220px auto 0;
            position: relative;
            z-index: 1;
            .title-line {
                width: 30px;
                height: 2px;
                background-color: var(--v-primary-base);
                margin: 20px 0;
            }
            .v-breadcrumbs {
                color: #ddb57e;
            }
        }
    }
    &--1 {
        padding-top: 80px;
    }
    .sub-sheet {
        max-width: 90%;
        margin: 0 auto;
        &--left {
            padding-top: 40px;
            > .v-image {
                margin-top: 40px;
            }
        }
        &--right {
            > .v-image {
                margin-top: 40px;
                margin-bottom: 40px;
            }
        }
    }
}
.brand-card {
    position: relative;
    margin-left: 104px;
    padding: 30px;
    padding-left: 134px;
    display: flex;
    align-items: center;
    border-bottom: 6px solid var(--v-primary-base);
    &__image {
        position: absolute;
        left: -104px;
        top: 50%;
        transform: translateY(-50%);
        overflow: hidden;
        .v-image {
            transition: all 0.5s;
        }
    }
    &__find {
        margin-top: 14px;
        font-size: var(--txt-font-size-sm);
        color: var(--v-primary-base);
        position: relative;
        padding-left: 30px;
        &::before {
            content: "";
            position: absolute;
            width: 20px;
            height: 1px;
            background: currentColor;
            left: 0;
            top: 54%;
        }
    }
    &:hover {
        .brand-card {
            &__image {
                .v-image {
                    transform: scale(1.1);
                }
            }
            &__find {
                animation: findOutMore 0.5s forwards ease-in-out;
                &::before {
                    animation: findOutMoreBefore 0.5s forwards ease-in-out;
                }
            }
        }
    }
}
@media (min-width: 768px) {
    .sub-section {
        &--visual {
            &__sheet {
                max-width: 600px;
                padding: 50px;
                margin: 0;
                position: absolute;
                top: 50%;
                right: var(--container-gutter);
                transform: translateY(-50%);
                .title-line {
                    width: 30px;
                    margin: 20px 0;
                }
            }
            .visual-image {
                &::after {
                    content: "";
                    position: absolute;
                    left: 14px;
                    bottom: 0;
                    width: 2px;
                    background-color: var(--v-primary-base);
                    transform: translateY(100%);
                    height: 0;
                }
            }
        }
    }
}
@media (min-width: 1232px) {
    .sub-section {
        &--1 {
            padding-top: 120px;
        }
        .sub-sheet {
            max-width: 500px;
            &--left {
                margin-left: 100px;
                padding-top: 80px;
                > .v-image {
                    margin-top: 80px;
                }
            }
            &--right {
                > .v-image {
                    margin-bottom: 80px;
                }
            }
        }
    }
}

// Animations
@keyframes findOutMore {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes findOutMoreBefore {
    from {
        width: 0;
    }
    to {
        width: 20px;
    }
}
</style>