var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('section', {
    staticClass: "sub-section sub-section--visual"
  }, [_c('v-container', [_c('v-img', {
    staticClass: "visual-image",
    attrs: {
      "src": "/images/company/about/vision/visual.jpg"
    }
  }, [_c('div', {
    staticClass: "vertical-title"
  }, [_vm._v("Vision & Mission")])]), _c('v-sheet', {
    staticClass: "sub-section--visual__sheet"
  }, [_c('v-breadcrumbs', {
    staticClass: "pa-0",
    attrs: {
      "items": _vm.breadcrumbs
    },
    scopedSlots: _vm._u([{
      key: "divider",
      fn: function () {
        return [_c('v-icon', {
          attrs: {
            "color": "#ddb57e"
          }
        }, [_vm._v("mdi-chevron-right")])];
      },
      proxy: true
    }, {
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-breadcrumbs-item', {
          attrs: {
            "href": item.href,
            "disabled": item.disabled
          }
        }, [_c('span', {
          staticClass: "font-size-12 font-weight-bold"
        }, [_vm._v(" " + _vm._s(item.text) + " ")])])];
      }
    }])
  }), _c('div', {
    staticClass: "title-line"
  }), _c('div', {
    staticClass: "tit tit--xxl font-tertiary mb-20px"
  }, [_vm._v("Vision & Mission")]), _c('p', {
    staticClass: "txt txt--xs line-height-16"
  }, [_vm._v("A common goal. We at Villeroy & Boch have always known how to create personal experiences with our unique ceramics. Spurred on by our passion to keep setting new standards, our forward-looking approach allows us to develop creative and innovative solutions for the future. Resulting in environments where our customers feel at home.")])], 1)], 1)], 1), _c('section', {
    staticClass: "sub-section sub-section--1"
  }, [_c('v-container', [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-sheet', {
    staticClass: "sub-sheet sub-sheet--left",
    attrs: {
      "color": "transparent"
    }
  }, [_c('div', {
    staticClass: "tit tit--xs font-tertiary mb-20px"
  }, [_vm._v("Our vision: A house becomes a home")]), _c('p', {
    staticClass: "txt txt--xs line-height-16 grey--text text--lighten-1"
  }, [_vm._v("A “home” is created only when a house is furnished in a way that reflects the personal vision and style of those who live in it. We help make this happen ma-1 for everyone, everywhere.")]), _c('v-img', {
    attrs: {
      "src": "/images/company/about/vision/image1.jpg"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-sheet', {
    staticClass: "sub-sheet sub-sheet--right",
    attrs: {
      "color": "transparent"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/company/about/vision/image2.jpg"
    }
  }), _c('div', {
    staticClass: "tit tit--xs font-tertiary mb-20px"
  }, [_vm._v("Our mission: We create feel-good moments and spaces using premium ceramics")]), _c('div', {
    staticClass: "txt txt--xs line-height-16 grey--text text--lighten-1"
  }, [_c('p', [_vm._v(" Always at the forefront of our endeavors is our strong understanding of the needs and wishes of our customers. This knowledge affords us the pleasure of being instrumental in helping to create a particular character for every home. Your home should be comfortable so we imbue our products with all our passion, creativity and design expertise, and pair it with our technical and conceptual know-how. With our brands, lifestyles and price segments, we offer both superior individual items for your home and perfect complete solutions. ")]), _c('p', [_vm._v("Ceramics is our strength and is in our genes. Wherever possible, we rely on the superior quality of ceramics over other materials. Our expertise and passion for ceramics drives us forward to set benchmarks and to always represent the utmost quality and innovation in each segment with our brands.")]), _c('p', [_vm._v("Employing our ceramics and design expertise, we build on the potential for diversification in supplementary product areas, thus creating living environments where our customers feel at home - right from the start and every day anew.")])])], 1)], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }