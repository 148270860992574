var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('section', {
    staticClass: "sub-section sub-section--visual"
  }, [_c('v-container', [_c('v-img', {
    staticClass: "visual-image ml-auto",
    attrs: {
      "max-width": "798",
      "src": "/images/company/about/figures/visual.jpg"
    }
  }), _c('v-sheet', {
    staticClass: "sub-section--visual__sheet"
  }, [_c('v-breadcrumbs', {
    staticClass: "pa-0",
    attrs: {
      "items": _vm.breadcrumbs
    },
    scopedSlots: _vm._u([{
      key: "divider",
      fn: function () {
        return [_c('v-icon', {
          attrs: {
            "color": "#ddb57e"
          }
        }, [_vm._v("mdi-chevron-right")])];
      },
      proxy: true
    }, {
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-breadcrumbs-item', {
          attrs: {
            "href": item.href,
            "disabled": item.disabled
          }
        }, [_c('span', {
          staticClass: "font-size-12 font-weight-bold"
        }, [_vm._v(" " + _vm._s(item.text) + " ")])])];
      }
    }])
  }), _c('div', {
    staticClass: "title-line"
  }), _c('div', {
    staticClass: "tit tit--xxl font-tertiary mb-20px"
  }, [_vm._v("The company in figures")]), _c('p', {
    staticClass: "txt txt--xs line-height-16"
  }, [_vm._v("An overview of key facts and figures about the company.")])], 1)], 1)], 1), _c('section', {
    staticClass: "sub-section sub-section--1"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center"
    }
  }, [_vm._l(_vm.items, function (item) {
    return [_c('v-col', {
      key: item,
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-card', {
      staticClass: "figures-card",
      attrs: {
        "href": item.link,
        "target": "_blank",
        "tile": "",
        "flat": "",
        "color": "white",
        "width": "274"
      }
    }, [_c('v-responsive', {
      attrs: {
        "aspect-ratio": 1 / 1
      }
    }, [_c('div', {
      staticClass: "figures-card__name"
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c('div', {
      staticClass: "figures-card__line"
    }), _c('div', {
      staticClass: "figures-card__number"
    }, [_vm._v(" " + _vm._s(item.number) + " ")]), item.button ? [_c('v-btn', {
      staticClass: "figures-card__button rounded-0",
      attrs: {
        "outlined": ""
      }
    }, [_vm._v(" " + _vm._s(item.button) + " ")])] : _vm._e()], 2)], 1)], 1)];
  })], 2)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }