<template>
    <client-page>
        <section class="sub-section sub-section--visual">
            <v-container>
                <v-img src="/images/company/history/tradition/visual.jpg" class="visual-image"> </v-img>
                <v-sheet class="sub-section--visual__sheet">
                    <v-breadcrumbs :items="breadcrumbs" class="pa-0">
                        <template v-slot:divider>
                            <v-icon color="#ddb57e">mdi-chevron-right</v-icon>
                        </template>
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                                <span class="font-size-12 font-weight-bold">
                                    {{ item.text }}
                                </span>
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <div class="title-line" />
                    <div class="tit tit--xxl font-tertiary mb-20px">A premium brand with tradition</div>
                    <p class="txt txt--xs line-height-16">Like very few other brands, Villeroy &amp; Boch is deeply rooted in European culture. And, in the way that only a great brand can, it has understood how to preserve its identity while still moving with the times.</p>
                </v-sheet>
            </v-container>
        </section>

        <section class="sub-section sub-section--1">
            <v-container>
                <div class="px-0px px-xl-50px">
                    <iframe src="https://www.youtube.com/embed/mxF7NOg4Mv8?si=jP6X1jTQWg0PERzV" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen class="youtube" />

                    <div class="tit tit--xs font-tertiary mt-40px mb-20px mt-xl-80px">German dynasties: Villeroy&amp;Boch</div>
                    <div class="txt txt--xs line-height-16 grey--text text--lighten-1">
                        <p>The film on Villeroy &amp; Boch's history explains how the German and French family came together over two hundred years ago to form one of the most successful dynasties. Pioneering spirit and visionary ideas: in an interview, descendants of François Boch and Nicolas Villeroy talk about the company that began with two small potteries</p>
                        <p>and grew to become a global enterprise.</p>
                        <p>
                            Film information (in German language) <br />
                            "Deutsche Dynastien" [Germany dynasties]: Villeroy &amp; Boch (43 min. / Germany, France, USA 2015 / WDR / produced by neue artfilm)
                        </p>
                    </div>
                </div>
            </v-container>
        </section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";

export default {
    components: {
        ClientPage,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "Company",
                href: "/company/about/figures",
            },
            {
                text: "역사와 전통",
                href: "/company/history/history",
            },
            {
                text: "전통",
                href: "/company/history/tradition",
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
    .v-main {
        background-color: var(--v-grey-lighten5);
    }
}
.sub-section {
    &--visual {
        &__sheet {
            max-width: 90%;
            padding: 30px;
            position: relative;
            z-index: 1;
            margin: -60px auto 0;
            .title-line {
                width: 30px;
                height: 2px;
                background-color: var(--v-primary-base);
                margin: 20px 0;
            }
            .v-breadcrumbs {
                color: #ddb57e;
            }
        }
        .visual-image {
            position: relative;
            overflow: visible;
        }
    }
    &--1 {
        padding-top: 40px;
    }
}
.youtube {
    width: 100%;
    aspect-ratio: 16/9;
}
@media (min-width: 1232px) {
    .sub-section {
        &--visual {
            &__sheet {
                max-width: 600px;
                padding: 50px;
                margin-top: -180px;
                margin-left: 50px;
                .title-line {
                    width: 30px;
                    margin: 20px 0;
                }
            }
            .visual-image {
                &::after {
                    content: "";
                    position: absolute;
                    left: 14px;
                    bottom: 0;
                    width: 2px;
                    background-color: var(--v-primary-base);
                    transform: translateY(100%);
                    height: 0;
                    animation: verticalLine 0.5s forwards ease-in-out;
                }
            }
        }
        &--1 {
            padding-top: 80px;
        }
    }
}
</style>