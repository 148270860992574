var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('section', {
    staticClass: "sub-section sub-section--visual"
  }, [_c('v-container', [_c('v-img', {
    staticClass: "visual-image",
    attrs: {
      "src": "/images/company/history/tradition/visual.jpg"
    }
  }), _c('v-sheet', {
    staticClass: "sub-section--visual__sheet"
  }, [_c('v-breadcrumbs', {
    staticClass: "pa-0",
    attrs: {
      "items": _vm.breadcrumbs
    },
    scopedSlots: _vm._u([{
      key: "divider",
      fn: function () {
        return [_c('v-icon', {
          attrs: {
            "color": "#ddb57e"
          }
        }, [_vm._v("mdi-chevron-right")])];
      },
      proxy: true
    }, {
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-breadcrumbs-item', {
          attrs: {
            "href": item.href,
            "disabled": item.disabled
          }
        }, [_c('span', {
          staticClass: "font-size-12 font-weight-bold"
        }, [_vm._v(" " + _vm._s(item.text) + " ")])])];
      }
    }])
  }), _c('div', {
    staticClass: "title-line"
  }), _c('div', {
    staticClass: "tit tit--xxl font-tertiary mb-20px"
  }, [_vm._v("A premium brand with tradition")]), _c('p', {
    staticClass: "txt txt--xs line-height-16"
  }, [_vm._v("Like very few other brands, Villeroy & Boch is deeply rooted in European culture. And, in the way that only a great brand can, it has understood how to preserve its identity while still moving with the times.")])], 1)], 1)], 1), _c('section', {
    staticClass: "sub-section sub-section--1"
  }, [_c('v-container', [_c('div', {
    staticClass: "px-0px px-xl-50px"
  }, [_c('iframe', {
    staticClass: "youtube",
    attrs: {
      "src": "https://www.youtube.com/embed/mxF7NOg4Mv8?si=jP6X1jTQWg0PERzV",
      "title": "YouTube video player",
      "frameborder": "0",
      "allow": "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
      "referrerpolicy": "strict-origin-when-cross-origin",
      "allowfullscreen": ""
    }
  }), _c('div', {
    staticClass: "tit tit--xs font-tertiary mt-40px mb-20px mt-xl-80px"
  }, [_vm._v("German dynasties: Villeroy&Boch")]), _c('div', {
    staticClass: "txt txt--xs line-height-16 grey--text text--lighten-1"
  }, [_c('p', [_vm._v("The film on Villeroy & Boch's history explains how the German and French family came together over two hundred years ago to form one of the most successful dynasties. Pioneering spirit and visionary ideas: in an interview, descendants of François Boch and Nicolas Villeroy talk about the company that began with two small potteries")]), _c('p', [_vm._v("and grew to become a global enterprise.")]), _c('p', [_vm._v(" Film information (in German language) "), _c('br'), _vm._v(" \"Deutsche Dynastien\" [Germany dynasties]: Villeroy & Boch (43 min. / Germany, France, USA 2015 / WDR / produced by neue artfilm) ")])])])])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }