var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('section', {
    staticClass: "sub-section sub-section--visual"
  }, [_c('v-container', [_c('v-img', {
    staticClass: "visual-image",
    attrs: {
      "max-width": "798",
      "src": "/images/company/history/history/visual.jpg"
    }
  }), _c('v-sheet', {
    staticClass: "sub-section--visual__sheet"
  }, [_c('v-breadcrumbs', {
    staticClass: "pa-0",
    attrs: {
      "items": _vm.breadcrumbs
    },
    scopedSlots: _vm._u([{
      key: "divider",
      fn: function () {
        return [_c('v-icon', {
          attrs: {
            "color": "#ddb57e"
          }
        }, [_vm._v("mdi-chevron-right")])];
      },
      proxy: true
    }, {
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-breadcrumbs-item', {
          attrs: {
            "href": item.href,
            "disabled": item.disabled
          }
        }, [_c('span', {
          staticClass: "font-size-12 font-weight-bold"
        }, [_vm._v(" " + _vm._s(item.text) + " ")])])];
      }
    }])
  }), _c('div', {
    staticClass: "title-line"
  }), _c('div', {
    staticClass: "tit tit--xxl font-tertiary mb-20px"
  }, [_vm._v("The company's history")]), _c('p', {
    staticClass: "txt txt--xs line-height-16"
  }, [_vm._v("Learn more about the beginnings of the company and go on a journey through the most important events in the history of Villeroy & Boch AG.")])], 1)], 1)], 1), _c('section', {
    staticClass: "sub-section sub-section--1"
  }, [_c('v-container', [_vm._l(_vm.items, function (item) {
    return [_c('v-sheet', {
      key: item,
      attrs: {
        "color": "transparent"
      }
    }, [_c('v-card', {
      staticClass: "text-center mx-auto pt-20px pt-xl-30px pb-16px pb-xl-22px position-relative z-1",
      attrs: {
        "color": "grey lighten-5",
        "max-width": "500"
      }
    }, [_c('div', {
      staticClass: "font-size-12 tertiary--text line-height-11 mb-8px"
    }, [_vm._v(" " + _vm._s(item.year) + " ")]), _c('h3', {
      staticClass: "tit tit--lg font-tertiary"
    }, [_vm._v(" " + _vm._s(item.name) + " ")])]), _vm._l(item.children, function (child) {
      return [_c('div', {
        key: child,
        staticClass: "history-item"
      }, [_c('v-row', {
        staticClass: "row--lg",
        attrs: {
          "align": "center"
        }
      }, [_c('v-col', {
        staticClass: "history-item__year",
        attrs: {
          "cols": "12",
          "xl": "auto"
        }
      }, [_c('v-card', {
        staticClass: "mx-auto position-relative z-1",
        attrs: {
          "color": "grey lighten-5",
          "rounded": "circle"
        }
      }, [_c('v-responsive', {
        staticClass: "d-flex align-center",
        attrs: {
          "aspect-ratio": 1 / 1
        }
      }, [_c('div', {
        staticClass: "tit tit--sm font-tertiary font-weight-bold tertiary--text text-center"
      }, [_vm._v(" " + _vm._s(child.year) + " ")])])], 1)], 1), _c('v-col', {
        staticClass: "history-item__content",
        attrs: {
          "cols": "12",
          "xl": ""
        }
      }, [_c('v-card', {
        staticClass: "grey lighten-5 py-14px pb-xl-0",
        attrs: {
          "max-width": "500",
          "data-aos": "fade-up"
        }
      }, [_c('div', {
        staticClass: "tit tit--xs mb-14px"
      }, [_vm._v(" " + _vm._s(child.name) + " ")]), child.image ? [_c('v-img', {
        attrs: {
          "src": child.image,
          "eager": ""
        }
      })] : _vm._e(), child.video ? void 0 : _vm._e(), _c('p', {
        staticClass: "txt txt--xs line-height-16 mt-14px",
        class: {
          'text-truncate-3': child.isTruncated
        }
      }, [_vm._v(" " + _vm._s(child.text) + " ")]), _c('v-btn', {
        staticClass: "mt-14px",
        attrs: {
          "outlined": "",
          "color": "primary",
          "x-small": ""
        },
        on: {
          "click": function ($event) {
            return _vm.toggleTruncate(child);
          }
        }
      }, [_vm._v(" " + _vm._s(child.isTruncated ? "더 읽기" : "접기") + " ")])], 2)], 1), _c('v-col', {
        staticClass: "history-item__blank",
        attrs: {
          "xl": ""
        }
      })], 1)], 1)];
    })], 2)];
  }), _c('v-sheet', {
    staticClass: "text-center pt-30px pb-22px mx-auto position-relative z-1",
    attrs: {
      "color": "grey lighten-5",
      "max-width": "500"
    }
  }, [_c('div', {
    staticClass: "tit tit--lg font-tertiary"
  }, [_vm._v("ANNIVERSARY YEAR 2023 - ALL ABOUT 275 YEARS CREATING HOMES")])]), _c('v-sheet', {
    staticClass: "mx-auto",
    attrs: {
      "color": "transparent",
      "max-width": "688"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/company/history/history/last.jpg"
    }
  }), _c('p', {
    staticClass: "txt txt--xs mt-14px line-height-16"
  }, [_vm._v("Since 1748, Villeroy & Boch has stood for quality and sophisticated aesthetics, for forward-looking ideas and techniques relating to ceramics. It has always been Villeroy & Boch's ambition to design our homes in order to enjoy life's moments. \"Creating Homes\" means more than furnishing a house. \"Creating Homes\" means realising one's personal ideas, one's own lifestyle and feeling at home.")])], 1)], 2)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }