<template>
    <client-page>
        <section class="sub-section sub-section--visual">
            <v-container>
                <v-img src="/images/company/innovation/products/visual.jpg" class="visual-image">
                    <div class="vertical-title">Innovative products</div>
                </v-img>
                <v-sheet class="sub-section--visual__sheet">
                    <v-breadcrumbs :items="breadcrumbs" class="pa-0">
                        <template v-slot:divider>
                            <v-icon color="#ddb57e">mdi-chevron-right</v-icon>
                        </template>
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                                <span class="font-size-12 font-weight-bold">
                                    {{ item.text }}
                                </span>
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <div class="title-line" />
                    <div class="tit tit--xxl font-tertiary mb-20px">Innovative strength since 1748</div>
                    <p class="txt txt--xs line-height-16">For over 270 years, innovations have been part of Villeroy &amp; Boch's corporate culture. They have helped to forge a positive image and drive the brand's success in over 125 countries. As well as high-quality design, the guiding aims behind our innovations are always functionality and added value for our customers. You can find a selection of Villeroy &amp; Boch's major innovations here.</p>
                </v-sheet>
            </v-container>
        </section>

        <section class="sub-section sub-section--1">
            <v-container>
                <v-row no-gutters justify="space-between">
                    <v-col cols="auto">
                        <v-sheet color="transparent" class="sub-sheet sub-sheet--left">
                            <div class="quote">
                                <p class="quote__text">
                                    <v-img src="/images/company/innovation/products/quote-before.svg" class="quote__mark" />
                                    It is vitally important that an innovation culture is enshrined at the highest level of a company and made an important priority.
                                    <v-img src="/images/company/innovation/products/quote-after.svg" class="quote__mark" />
                                </p>
                                <span class="quote__from">Dr Peter Delwing (Innovation Manager)</span>
                            </div>

                            <v-img src="/images/company/innovation/products/image1.jpg" class="my-40px my-xl-80px" />

                            <div class="tit tit--xs font-tertiary mb-20px">DirectFlush toilets - Intelligent engineering</div>
                            <p class="txt txt--xs line-height-16 grey--text text--lighten-1">
                                Instead of a conventional rim, Villeroy &amp; Boch DirectFlush toilets have an intelligent flushing system: it rinses the entire inner surface of the bowl without splashing - thanks to a splash guard at the top of the bowl and the higher positioning of the water jets. Consuming just 3 or 4.5 litres of water per activation, DirectFlush ensures maximum flushing efficiency. The DirectFlush models are completely glazed for quick and easy cleaning. In 2015, sales of
                                DirectFlush and HygieneFlush toilets doubled in comparison with the previous year.
                            </p>

                            <v-img src="/images/company/innovation/products/image2.jpg" class="my-40px my-xl-80px" />

                            <div class="tit tit--xs font-tertiary mb-20px">TitanCeram - a material with a future</div>
                            <p class="txt txt--xs line-height-16 grey--text text--lighten-1">
                                TitanCeram is an innovative material bringing together experience from over 260 years of ceramic production and developed specially for high-quality premium designs. The new ceramic material passes through specially adapted production steps to allow the creation of products with uniquely precise shapes. TitanCeram stands for outstanding design highlights, such as the Octagon pedestal washbasin, a novel and precise shape featuring extremely thin walls and sharply
                                defined edges.
                            </p>
                        </v-sheet>
                    </v-col>

                    <v-col cols="auto">
                        <v-sheet color="transparent" class="sub-sheet sub-sheet--right">
                            <v-img src="/images/company/innovation/products/image3.jpg" class="my-40px mt-xl-0 mb-xl-80px" />

                            <div class="tit tit--xs font-tertiary mb-20px">Manufacture Rock</div>
                            <p class="txt txt--xs line-height-16 grey--text text--lighten-1">The Manufacture Rock collection is inspired by slate, whose many layers of rock, formed millions of years ago, constantly reveal new patterns, structures and colours. The lifelike slate surface of the tableware made of solid-coloured, black Premium Porcelain, reproduced in fine relief with a hint of glaze, has a fascinatingly authentic feel.</p>

                            <v-img src="/images/company/innovation/products/image4.jpg" class="my-40px my-xl-80px" />

                            <div class="tit tit--xs font-tertiary mb-20px">NewWave – an eternal shape</div>
                            <p class="txt txt--xs line-height-16 grey--text text--lighten-1">Anyone familiar with Villeroy &amp; Boch will also know NewWave: since its launch in 2001, NewWave has been an essential part of the Villeroy &amp; Boch range. what makes the range special: Villeroy &amp; Boch's NewWave series represents a break from the traditional rounded shape of crockery, creating a completely new design with an original wave effect. In 2004, the special design and innovative die-casting process used to produce the cup, allowing the handle to be harmoniously integrated in the overall shape, earned Villeroy &amp; Boch the "Innovationspreis der deutschen Wirtschaft" [German Economy Innovation Award] for its NewWave collection.</p>

                            <v-img src="/images/company/innovation/products/image5.jpg" class="my-40px my-xl-80px" />

                            <div class="tit tit--xs font-tertiary mb-20px">ViClean – a new dimension of toilet culture</div>
                            <p class="txt txt--xs line-height-16 grey--text text--lighten-1">Hygiene, comfort, sustainability and innovative design are the convincing features of Villeroy &amp; Boch's shower-toilets. Shower-toilets are an established part of toilet hygiene in Asia. The time has now come to convince European consumers of the benefits of gentle and personalised intimate care at the touch of a button. In cooperation with leading experts and well-known designers, Villeroy &amp; Boch has succeeded in combining both the functionality and aesthetics of a toilet and bidet in its ViClean concept.</p>
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-container>
        </section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";

export default {
    components: {
        ClientPage,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "Company",
                href: "/company/about/figures",
            },
            {
                text: "품질과 혁신",
                href: "/company/innovation/products",
            },
            {
                text: "창의적 제품",
                href: "/company/innovation/products",
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
    .v-main {
        background-color: var(--v-grey-lighten5);
    }
}
.sub-section {
    &--visual {
        &__sheet {
            max-width: 90%;
            padding: 30px;
            position: relative;
            z-index: 1;
            margin: -60px auto 0;
            .title-line {
                width: 30px;
                height: 2px;
                background-color: var(--v-primary-base);
                margin: 20px 0;
            }
            .v-breadcrumbs {
                color: #ddb57e;
            }
        }
        .visual-image {
            position: relative;
            overflow: visible;
            .vertical-title {
                display: none;
            }
        }
    }
    &--1 {
        padding-top: 40px;
    }
    .sub-sheet {
        max-width: 90%;
        margin: 0 auto;
        &--left {
            padding-top: 40px;
            // > .v-image {
            //     margin-top: 40px;
            // }
        }
        &--right {
            // > .v-image {
            //     margin-top: 40px;
            //     margin-bottom: 40px;
            // }
        }
    }
}
.quote {
    text-align: center;
    &__text {
        font-family: var(--font-tertiary);
        font-style: italic;
        font-size: var(--tit-font-size-sm);
        line-height: 1.2;
        padding: 14px 0;
    }
    &__mark {
        display: inline-flex;
        transform: translateY(-4%);
        width: 34px;
    }
    &__from {
        margin-top: 6px;
        font-size: 12px;
        font-weight: 700;
        color: #ddb57e;
    }
}
@media (min-width: 1232px) {
    .sub-section {
        &--visual {
            &__sheet {
                max-width: 600px;
                padding: 50px;
                margin-top: -180px;
                margin-left: 50px;
                .title-line {
                    width: 30px;
                    margin: 20px 0;
                }
            }
            .visual-image {
                &::after {
                    content: "";
                    position: absolute;
                    left: 14px;
                    bottom: 0;
                    width: 2px;
                    background-color: var(--v-primary-base);
                    transform: translateY(100%);
                    height: 0;
                    animation: verticalLine 0.5s forwards ease-in-out;
                }
                .vertical-title {
                    display: block;
                    position: absolute;
                    left: -90px;
                    bottom: -250px;
                    font-size: var(--tit-font-size-sm);
                    font-family: var(--font-tertiary);
                    font-style: italic;
                    color: var(--v-primary-base);
                    line-height: 1;
                    transform: translateY(-40px) rotate(90deg);
                    opacity: 0;
                    animation: verticalTitle 0.5s forwards ease-in-out 0.5s;
                }
            }
        }
        &--1 {
            padding-top: 40px;
        }
        .sub-sheet {
            max-width: 500px;
            &--left {
                margin-left: 100px;
                padding-top: 80px;
                // > .v-image {
                //     margin-top: 80px;
                // }
            }
            &--right {
                transform: translateY(-160px);
                // > .v-image {
                //     margin-bottom: 80px;
                // }
            }
        }
    }
}

// Animations
@keyframes verticalLine {
    from {
        height: 0;
    }
    to {
        height: 120px;
    }
}
@keyframes verticalTitle {
    from {
        transform: translateY(-40px) rotate(90deg);
        opacity: 0;
    }
    to {
        transform: translateY(0) rotate(90deg);
        opacity: 1;
    }
}
</style>