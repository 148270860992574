<template>
    <client-page>
        <section class="sub-section sub-section--visual">
            <v-container>
                <v-img max-width="798" src="/images/company/about/figures/visual.jpg" class="visual-image ml-auto"></v-img>
                <v-sheet class="sub-section--visual__sheet">
                    <v-breadcrumbs :items="breadcrumbs" class="pa-0">
                        <template v-slot:divider>
                            <v-icon color="#ddb57e">mdi-chevron-right</v-icon>
                        </template>
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                                <span class="font-size-12 font-weight-bold">
                                    {{ item.text }}
                                </span>
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <div class="title-line" />
                    <div class="tit tit--xxl font-tertiary mb-20px">The company in figures</div>
                    <p class="txt txt--xs line-height-16">An overview of key facts and figures about the company.</p>
                </v-sheet>
            </v-container>
        </section>

        <section class="sub-section sub-section--1">
            <v-container>
                <v-row justify="center" class="row--lg">
                    <template v-for="item in items">
                        <v-col cols="auto" :key="item">
                            <v-card :href="item.link" target="_blank" tile flat color="white" width="274" class="figures-card">
                                <v-responsive :aspect-ratio="1 / 1">
                                    <div class="figures-card__name">
                                        {{ item.name }}
                                    </div>
                                    <div class="figures-card__line" />
                                    <div class="figures-card__number">
                                        {{ item.number }}
                                    </div>
                                    <template v-if="item.button">
                                        <v-btn outlined class="figures-card__button rounded-0">
                                            {{ item.button }}
                                        </v-btn>
                                    </template>
                                </v-responsive>
                            </v-card>
                        </v-col>
                    </template>
                </v-row>
            </v-container>
        </section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";

export default {
    components: {
        ClientPage,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "Company",
                href: "/company/about/figures",
            },
            {
                text: "빌레로이앤보흐",
                href: "/company/about/figures",
            },
            {
                text: "성과",
                href: "/company/about/vision",
            },
        ],
        items: [
            {
                name: "Turnover in 2023 (in millions of euros)",
                number: "902",
                link: "https://www.villeroyboch-group.com/en/investor-relations/publications.html",
                button: "더 알아보기(영문)",
            },
            {
                name: "Established",
                number: "1,748",
                link: "",
                button: "더 알아보기",
            },
            {
                name: "DirectFlush sold to date: over",
                number: "1,000,000",
                link: "/company/innovation/products",
                button: "더 알아보기",
            },
            {
                name: "The Group`s Brands",
                number: "12",
                button: "더 알아보기",
            },
            {
                name: "Bathroom and Wellness showrooms worldwide",
                number: "12,200",
            },
            {
                name: "Dining & Lifestyle points of sale worldwide",
                number: "4,000",
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
    .v-main {
        background-color: var(--v-grey-lighten5);
    }
}
.sub-section {
    &--visual {
        .container {
            position: relative;
        }
        &__sheet {
            max-width: 90%;
            padding: 30px;
            margin: -220px auto 0;
            position: relative;
            z-index: 1;
            .title-line {
                width: 30px;
                height: 2px;
                background-color: var(--v-primary-base);
                margin: 20px 0;
            }
            .v-breadcrumbs {
                color: #ddb57e;
            }
        }
    }
    &--1 {
        padding-top: 40px;
    }
}
.figures-card {
    text-align: center;
    position: relative;
    > .v-responsive {
        display: flex;
        justify-content: center;
    }
    &__name {
        padding: 40px 60px 0;
        font-size: 12px;
        color: #ddb57e;
        text-transform: uppercase;
        font-weight: 700;
        line-height: 1.1;
    }
    &__line {
        width: 20px;
        height: 2px;
        background-color: var(--v-primary-base);
        margin: 12px auto 0;
        transition: all ease-out 0.15s;
    }
    &__number {
        font-size: var(--tit-font-size-xxl);
        font-family: var(--font-tertiary);
        letter-spacing: -0.1em;
        line-height: 1;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    &__button {
        border-width: 2px;
        width: 140px;
        color: var(--v-primary-base);
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 30px;
    }
    &:hover {
        .figures-card {
            &__line {
                width: 42px;
            }
            &__button {
                color: #fff;
                background-color: var(--v-primary-base);
                border-color: var(--v-primary-base);
            }
        }
    }
}
@media (min-width: 768px) {
    .sub-section {
        &--visual {
            &__sheet {
                max-width: 600px;
                padding: 50px;
                margin: 0;
                position: absolute;
                top: 50%;
                left: var(--container-gutter);
                transform: translateY(-50%);
                .title-line {
                    width: 30px;
                    margin: 20px 0;
                }
            }
        }
    }
}
@media (min-width: 1232px) {
    .sub-section {
        &--1 {
            padding-top: 80px;
        }
    }
}
</style>